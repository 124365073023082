/**!
 * NeuAdobePass v1.4.20200430
 */
/**
 * Provide API to get Adobe Pass user status and token
 *
 * API:
 * function getUserStatus(callback);
 * Return user final status. If user status cached, it will return immediately.
 * @param callback, with data object
 * "status"	can be: anonymous, authenticated, authorized
 * "data"	related data, like mvpdId to authenticated user, resources to authorized user.
 * Sample data:
 * {"status":"anonymous","isLoggedIn":false}
 * {"status":"authenticated","isLoggedIn":true,"data":{"id":"4707164d-e66f-4dec-8085-3e511b2b088a","mvpdId":"Charter_Direct"}}
 * {"status":"authorized","isLoggedIn":true,"data":{"id":"4707164d-e66f-4dec-8085-3e511b2b088a","mvpdId":"Charter_Direct","resources":["univision","galavision","deportes","fusion"]}}
 *
 * function getMvpdGuid(callback);
 * Return mvpd + guid for accesstoken service use
 * @param callback
 * Sample data: 'Comcast_SSO:1a3d283f11a92eb516a664383876381f'
 *
 * function getToken(requestedResourceId, callback);
 * Return Adobe Pass authorization token
 * @param requestedResourceId		optional, can be omitted for accesstoken use. pure string or mrss encoded string
 * @param callback		return token and corresponding resourceId. object.
 * Sample data:
 * {"requestedResourceId":"deportes","token":"xxxxx"}    // normal
 * {"requestedResourceId":"deportes","errorCode":"notAuthorized",errorMessage:"You are not authorized to view this content."} //means no authorization to the content
 * {"requestedResourceId":"<rss version="2.0">xxxxx</rss>","token":"xxxxx"}    // if using MRSS
 * {"requestedResourceId":"<rss version="2.0">xxxxx</rss>","errorCode":"notAuthorized",
 * 	"errorMessage":"Your Parental Control settings do not allow you to view this content. https://customer.xfinity.com/Secure/OnlineParentalControls.aspx"}    // if blocked by tvRating
 *
 * function getUserStatusFromCache();
 * Return user status from cache.
 * The object structure is same as getUserStatus()
 * The cache is based on global variables defined in base adobepass components which based on cookie values
 * Because these variables may change, component read the value only once when init
 *
 * function onUserStatusChanged (callback);
 * This will trigger when async user status loaded.
 * @param callback, with data object:
 * "changed"	can be: true/false
 * "user"	user status object
 *
 * Dependency:
 * 1) base/site/scripts/adobepass.js
 * 2) global variables:
 * 	ae
 * 	g_nlMvpdId
 * 	g_nlAdobePassUseMRSS
 * 	g_nlAdobePassAuthorizedResourceIds [univisionnow]
 * 	g_nlAdobePassRequestedResourceIdOrigin [univisionnow]
 * 	authorizedResourceIds (user cache) [univisionnow]
 * 	mvpdProvider (user cache) [univisionnow]
 * 	mvpdUserId (user cache) [univisionnow]
 * 3) functions
 * 	function AEGetAccessEnabler()
 * 	function AECallGetAuthorization(ae, refreshUrl, resourceId)
 * 	function nlHandleAdobePassMessage(msg, debugOnly)
 * 	function processMrss(resourceId, data)
 *
 * Extended functions:
 * 1) function setMetadataStatus(key, encrypted, data) in base/site/scripts/adobepass.js
 * 2) function setToken(requestedResourceID, token) in base/site/scripts/adobepass.js
 * 3) function tokenRequestFailed(requestedResourceID, requestErrorCode, requestErrorDetails) in base/site/scripts/adobepass.js
 * 4) function sendTrackingData(trackingEventType, trackingData)
 * 5) function nlHandleAdobePassMessage(msg, debugOnly) in base/site/scripts/adobepass.js
 * 6) function nlHandleAdobePassLoggedOut(mvpd) in base/site/scripts/adobepass.js
 */
(function (window, document) {
	var AdobePassService = (function () {
		var initialized;
		var debugLevel = 0;

		var user = null;		// user data object
		var _userData = null;	// user temp data object
		var _userId = null;	// user temp id (mvpdUserId)
		var cachedUser = null;		// user cached data object
		var userGuid = null;	// user unique identity
		var _guid = null;	// user temp guid

		var userStatusRequestQueue = [];
		var userStatusChangedCallbackQueue = [];
		var tokenRequestQueue = {};
		var tokenRequestConcurrency = {};
		var inst;

		function init() {
			return {
				setDebugLevel: function (level) {
					debugLevel = level;
					try {
						window.localStorage.setItem("neuap.debugLevel", level);
					} catch (e) {
					}
				},
				init: function () {
					inst = this;
					if (window.AECallGetAuthorization === undefined) {
						console.log("base/site/scripts/adobepass.js not available, init failed.");
						return;
					}

					if (window.g_mvpdsConfig === null) {
						console.log("mvpd configuration not available, init failed.");
						return;
					}

					// Extends functions
					// Note: If these base functions throw exceptions, the component will be affected
					extendFunction("nlHandleAdobePassMessage", handleAdobePassMessage);
					extendFunction("nlHandleAdobePassLoggedOut", handleAdobePassLoggedOut);
					extendFunction("setMetadataStatus", setMetadataStatus);
					extendFunction("setToken", setToken);
					extendFunction("tokenRequestFailed", tokenRequestFailed);
					extendFunction("sendTrackingData", sendTrackingData);

					// Get user and cache when init, because the global variable will change.
					cachedUser = this.getUserStatusFromCache();
					userGuid = getCookie("apGuid");
					try {
						debugLevel = window.localStorage.getItem("neuap.debugLevel");
					} catch (e) {
					}
				},

				/**
				 * Return user status from cache.
				 * The object structure is same as getUserStatus()
				 * The cache is based on global variables defined in base adobepass components which based on cookie values
				 * Because these variables may change, component read the value only once when init
				 */
				getUserStatusFromCache: function () {
					var ret;
					if (cachedUser) {
						ret = cachedUser;
					} else {
						ret = {};
						if (window.mvpdProvider) {
							ret.isLoggedIn = true;
							ret.data = {};
							ret.data.mvpdId = window.mvpdProvider;
							ret.data.id = window.mvpdUserId;
							if (window.authorizedResourceIds && window.authorizedResourceIds != "none") {
								ret.data.resources = window.authorizedResourceIds.split(",");
								ret.status = "authorized";
							} else {
								ret.status = "authenticated";
							}
						} else {
							ret.status = "anonymous";
							ret.isLoggedIn = false;
						}
					}
					return ret;
				},

				/**
				 * Return user final status. If user status cached, it will return immediately.
				 * @param callback, with data object:
				 * "status"	can be: anonymous, authenticated, authorized
				 * "isLoggedIn"	boolean
				 * "data"	related data, like mvpdId to authenticated user, resources to authorized user.
				 */
				getUserStatus: function (callback) {
					if (user) {
						if (callback) {
							callback(user);
						} else {
							return user;
						}
					} else {
						if (callback) {
							userStatusRequestQueue.push(callback);
						} else {
							return null;
						}
					}
				},

				/**
				 * This will trigger when async user status loaded.
				 * @param callback, with data object:
				 * "changed"	can be: true/false
				 * "user"	user status object
				 */
				onUserStatusChanged: function (callback) {
					userStatusChangedCallbackQueue.push(callback);
					if (user != null) {
						checkUserStatusChange();
					}
				},

				/**
				 * Return guid (userId MD5 hashed)
				 * @param callback
				 */
				getMvpdGuid: function (callback) {
					if (!callback) {
						console.log('callback can\'t be null');
						return;
					}
					this.getUserStatus(function (user) {
						if (userGuid) {
							callback(user.data.mvpdId + ':' + userGuid);
						} else {
							callback(null);
						}
					});
				},

				/**
				 * Return Adobe Pass authorization token
				 * @param requestedResourceId		optional, can be omitted for accesstoken use. pure string or mrss encoded string
				 * @param callback		return token and corresponding resourceId. object.
				 * Sample data:
				 * {"requestedResourceId":"deportes","token":"xxxxx"}    // normal
				 * {"requestedResourceId":"deportes","errorCode":"notAuthorized",errorMessage:"You are not authorized to view this content."} //means no authorization to the content
				 * {"requestedResourceId":"<rss version="2.0">xxxxx</rss>","token":"xxxxx"}    // if using MRSS
				 * {"requestedResourceId":"<rss version="2.0">xxxxx</rss>","errorCode":"notAuthorized",
				 * 	"errorMessage":"Your Parental Control settings do not allow you to view this content. https://customer.xfinity.com/Secure/OnlineParentalControls.aspx"}    // if blocked by tvRating
				 */
				getToken: function (requestedResourceId, callback) {
					if (typeof requestedResourceId === "function") {
						callback = requestedResourceId;
						requestedResourceId = null;
					}
					if (!callback) {
						console.log("callback can't be null");
						return;
					}
					var noneResourceIdToken = requestedResourceId === null;
					// Before first authZ returns, g_nlAdobePassAuthorizedResourceIds will be null, hold all request.
					// After first authZ, g_nlAdobePassAuthorizedResourceIds won't be null
					if (window.g_nlAdobePassAuthorizedResourceIds !== null) {
						var authorizedResourceIds = processResource(window.g_nlAdobePassAuthorizedResourceIds);
						if (requestedResourceId === null) {
							if (authorizedResourceIds.length > 0) {
								requestedResourceId = window.processMrss && window.processMrss(authorizedResourceIds[0]) || authorizedResourceIds[0];
							} else {
								tokenRequestFailed([null, 'notAuthorized', 'You are not authorized to view this content.']);
							}
						}

						if (!tokenRequestConcurrency[requestedResourceId]) {
							AECallGetAuthorization(AEGetAccessEnabler(), null, requestedResourceId);
							tokenRequestConcurrency[requestedResourceId] = true;
						}
					}

					// Put request in queue
					var requestedResourceIdKey = noneResourceIdToken ? "default" : requestedResourceId;
					if (!tokenRequestQueue[requestedResourceIdKey]) {
						tokenRequestQueue[requestedResourceIdKey] = [];
					}
					tokenRequestQueue[requestedResourceIdKey].push(callback);
				},
			};
		}

		/**
		 * Extend base handleAdobePassMessage() to get notified the user status changed, to then process user status.
		 * @param args
		 */
		function handleAdobePassMessage(args) {
			var msg = args[0];
			var status = null;
			var data = null;
			if (msg.indexOf("Loading...") == 0) {
			} else if (msg.indexOf("Initializing...") == 0) {
			} else if (msg.indexOf("Authenticating...") == 0) {
			} else if (msg.indexOf("Authenticated to ") == 0) {
				// In this case, sendTrackingData() will be triggered
			} else if (msg.indexOf("Authenticated, obtaining authorization...") == 0) {
			} else if (msg.indexOf("Authentication Failed:") == 0) {
				status = "anonymous";
			} else if (msg.indexOf("Preauthorized: ") == 0) {
				status = "authorized";
				var authorizedResourceIds = processResource(window.g_nlAdobePassAuthorizedResourceIds);
				data = {mvpdId: window.g_nlMvpdId || window.mvpdProvider, "resources": authorizedResourceIds};
			} else if (msg.indexOf("Authorization Cancelled") == 0) {
				status = "authenticated";
				data = {mvpdId: window.g_nlMvpdId || window.mvpdProvider};
			} else if (msg.indexOf("Authorizing ") == 0) {
				// Assume we will always call AECallGetAuthorization(or ae.getAuthorization) in preauthorizedResources(), to get default token for nlps use.
				// base function preauthorizedResources() will always trigger Authorizing even if no resources, which is wrong, but do let us always know the 'authorizing' status.
				// extract resourceId 'univision' from msg: 'Authorizing univision...'
				var resourceId = msg.substring("Authorizing ".length, msg.indexOf("..."));
				tokenRequestConcurrency[resourceId] = true;
				var authorizedResourceIds = processResource(window.g_nlAdobePassAuthorizedResourceIds);
				if (authorizedResourceIds && authorizedResourceIds.length > 0) {
					status = "authorized";
					data = {mvpdId: window.g_nlMvpdId || window.mvpdProvider, "resources": authorizedResourceIds};

					// Release all resource token request that is hold, when authorized for the first time
					if (!_userData || _userData.status !== 'authorized') {
						for (var resourceIdInQueue in tokenRequestQueue) {
							if (resourceIdInQueue != resourceId && resourceIdInQueue != 'default') {
								AECallGetAuthorization(AEGetAccessEnabler(), null, resourceIdInQueue);
								tokenRequestConcurrency[resourceId] = true;
							}
						}
					}
				} else {
					status = "authenticated";
					// g_nlMvpdId will be null if resources is null
					data = {mvpdId: window.g_nlMvpdId || window.mvpdProvider};
				}
				// } else if (msg.indexOf("Authorization Failed: ") == 0) {
				// 	// base function preauthorizedResources() will always trigger Authorizing even if no resources, which is wrong.
				// 	// This is why it will fail here if the last one of g_nlAdobePassResourceIds is not authorized
				// 	status = "authenticated";
				// 	// g_nlMvpdId will be null if resources is null
				// 	data = {mvpdId: window.g_nlMvpdId || window.mvpdProvider};
			}

			if (status) {
				_userData = {};
				_userData.status = status;
				_userData.isLoggedIn = _userData.status === 'authenticated' || _userData.status === 'authorized';
				if (data != null) {
					_userData.data = {};
					for (var key in data) {
						_userData.data[key] = data[key];
					}
					// Only when auto-login and guid not changed, we can use the mvpdUserId from cache
					if (_userId) {
						_userData.data.id = _userId;
					}
				}
				debugLevel && console.log("userData generated, " + (_userId ? 'has userId' : 'no userId'));
				checkUserStatus();

				if (_userData.status === 'anonymous' || _userData.status === 'authenticated') {
					for (var resourceIdInQueue in tokenRequestQueue) {
						tokenRequestFailed([resourceIdInQueue != "default" ? resourceIdInQueue : null, 'notAuthorized', 'You are not authorized to view this content.']);
					}
				}
			}
		}

		function setMetadataStatus(args) {
			var key = args[0], encrypted = args[1], data = args[2];
			if (key === 'userID') {
				// Cache new guid only when new userId loaded.
				userGuid = _guid;
				setCookie("apGuid", userGuid, 30);

				debugLevel && console.log("userId loaded");

				if (_userData == null) {
					_userId = data;
				} else {
					_userData.data.id = data;
					debugLevel && console.log("userData completed");
					checkUserStatus();
				}
			}
		}

		function setToken(args) {
			var requestedResourceId = args[0], token = args[1];
			var data = {requestedResourceId: requestedResourceId, token: token};
			handleTokenRequest(data);
		}

		function tokenRequestFailed(args) {
			var requestedResourceID = args[0], requestErrorCode = args[1], requestErrorDetails = args[2];
			var data = {
				requestedResourceId: requestedResourceID,
				errorCode: requestErrorCode,
				errorMessage: requestErrorDetails
			};
			handleTokenRequest(data);
		}

		function handleTokenRequest(data) {
			var requestedResourceId = data.requestedResourceId;
			if (tokenRequestQueue[requestedResourceId]) {
				while (tokenRequestQueue[requestedResourceId].length > 0) {
					tokenRequestQueue[requestedResourceId].pop()(data);
				}
			}
			// If token returns, means it is a successful request, release all noneResourceId request.
			if (data.token) {
				if (tokenRequestQueue["default"]) {
					while (tokenRequestQueue["default"].length > 0) {
						// The returned 'requestedResourceId' is the real requested one not the 'null' value
						tokenRequestQueue["default"].pop()(data);
					}
				}
			}
			tokenRequestConcurrency[requestedResourceId] = false;
		}

		function sendTrackingData(args) {
			var trackingEventType = args[0], trackingData = args[1];
			if (trackingEventType === "authenticationDetection" && (trackingData[0] == true || trackingData[0] == "true")) {
				// TODO we can also get and cache mvpdId here.
				var mvpdId = trackingData[1];
				var guid = trackingData[2];
				var _cachedUser = inst.getUserStatusFromCache();
				if (_cachedUser.isLoggedIn) {
					if (userGuid === guid) {
						// When auto-login, and user(account) doesn't changed, get userId from cache
						_userId = _cachedUser.data.id;
					} else {
						// When user changed, need to reload userData.
						window.mvpdProvider = "";
						window.authorizedResourceIds = "";
						window.mvpdUserId = "";
						debugLevel && console.log("guid changed, clear user cache");
					}
				}
				_guid = guid;
			}
		}

		function handleAdobePassLoggedOut(mvpd) {
			delCookie("apGuid");
			_userData = null;
			_userId = null;
			user = null;
			cachedUser = null;
			_guid = null;
			userGuid = null;

			document.dispatchEvent(new CustomEvent("neu.userStatusChanged"));
			document.dispatchEvent(new CustomEvent("userStatusChanged"));	//backward compatible with nlat.js
		}

		function checkUserStatus() {
			if (_userData && (_userData.status == "anonymous" || (_userData.status == "authenticated" || _userData.status == "authorized") && _userData.data.id != undefined)) {
				user = _userData;
				while (userStatusRequestQueue.length > 0) {
					userStatusRequestQueue.pop()(user);
				}
				checkUserStatusChange();
			}
		}

		function checkUserStatusChange() {
			if (user != null) {
				var changed = true;
				var _cachedUser = inst.getUserStatusFromCache();
				if (user.status == _cachedUser.status && user.isLoggedIn == _cachedUser.isLoggedIn && (user.data == null && _cachedUser.data == null
						|| user.data != null && _cachedUser.data != null && user.data.id === _cachedUser.data.id
						&& user.data.mvpdId === _cachedUser.data.mvpdId && JSON.stringify(user.data.resources) === JSON.stringify(_cachedUser.data.resources))) {
					changed = false;
				}
				var ret = {changed: changed, user: user};
				debugLevel && console.log("userStatus: " + JSON.stringify(ret));
				while (userStatusChangedCallbackQueue.length > 0) {
					// FIXME: wrong implementation here. Queue shouldn't be cleaned after userStatus changed.
					userStatusChangedCallbackQueue.pop()(ret);
				}
				if (changed) {
					cachedUser = user;
					document.dispatchEvent(new CustomEvent("neu.userStatusChanged"));
					document.dispatchEvent(new CustomEvent("userStatusChanged"));	//backward compatible with nlat.js
				}
			}
		}

		/**
		 * Filter resources, remove 'none'
		 * @param resources string
		 * @returns {*}
		 */
		function processResource(resources) {
			var ret = [];
			for (var idx in resources) {
				if (resources[idx] !== 'none') {
					ret[ret.length] = resources[idx];
				}
			}
			return ret;
		}

		// ------------------------ Util Functions ------------------------

		/**
		 * Extend function
		 * @param fnName  type: string. function name under windows that needs to be extended.
		 * @param extendedFn  type: function.
		 */
		function extendFunction(fnName, extendedFn) {
			var fn;
			if (fnName.indexOf(".") == -1) {
				fn = window[fnName];
			} else {
				if (window[fnName.split(".")[0]] != undefined) {
					fn = window[fnName.split(".")[0]][fnName.split(".")[1]];
				}
			}
			if (typeof fn === "function") {
				var extFnName = "__neuAdobePassExtend__" + fnName;	// TODO: handle duplicated name?
				window[extFnName] = fn;
				var newFn = function () {
					var ret = window[extFnName].apply(this, arguments);
					return extendedFn.call(this, arguments, ret);
				};
				if (fnName.indexOf(".") > -1) {
					window[fnName.split(".")[0]][fnName.split(".")[1]] = newFn;
				} else {
					window[fnName] = newFn;
				}
			} else {
				console.log("Function: '" + fnName + "' is undefined or is not a function.");
			}
		}

		// Event Polyfill
		// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
		function CustomEvent(event, params) {
			params = params || {bubbles: false, cancelable: false, detail: null};
			var evt = document.createEvent('CustomEvent');
			evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
			return evt;
		}

		function setCookie(name, value, days) {
			document.cookie =
					name + "=" + encodeURIComponent(value)
					+ ";path=/"
					+ ";domain=" + ((document.domain.split(".").length == 2) ? document.domain : document.domain.substr(document.domain.indexOf(".")))
					+ ";expires=" + (new Date(Date.now() + days * 24 * 60 * 60 * 1000)).toUTCString();
		}

		function getCookie(name) {
			if (document.cookie.length > 0) {
				var startIdx, endIdx;
				startIdx = document.cookie.indexOf(name + "=");
				if (startIdx != -1) {
					startIdx = startIdx + name.length + 1;
					endIdx = document.cookie.indexOf(";", startIdx);
					if (endIdx == -1) {
						endIdx = document.cookie.length;
					}
					return unescape(document.cookie.substring(startIdx, endIdx));
				}
			}
			return null;
		}

		function delCookie(name) {
			setCookie(name, null, -1);
		}

		// ------------------------ ------------------------

		return {
			getInstance: function () {
				if (!initialized) {
					initialized = init();
				}
				return initialized;
			}
		};
	})();

	window.neuap = AdobePassService.getInstance();
	window.neuap.init();
}(window, document));
